<template>
    <v-container fluid style="padding-bottom: 100px;">
        <v-row class="mb-2">
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                            >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                    <v-spacer></v-spacer>
                </div>
            </v-col>
            <v-col cols="12">
                <v-card outlined class="p-2">
                    <v-container fluid>
                        <v-row>
                            <v-col xs="6" sm="6" md="2" class="col-6">
                                <h6 class="blue-lcd" style="font-weight:600">Department</h6>
                                <v-autocomplete
                                    solo
                                    dense
                                    clearable
                                    v-model="department"
                                    :items="departments"
                                    item-value="dept_id"
                                    :item-text="item => item.dept_id +' - '+ item.dept_name"
                                    class="border-12"
                                    hide-details=true
                                    @change="(event) => getSection(event)"
                                    :disabled="disable_deptid"
                                ></v-autocomplete>
                            </v-col>
                            <v-col xs="6" sm="6" md="2" class="col-6">
                                <h6 class="blue-lcd" style="font-weight:600">Section</h6>
                                <v-autocomplete
                                    solo
                                    dense
                                    clearable
                                    v-model="section"
                                    :items="sections"
                                    item-value="sect_id"
                                    item-text="sect_name"
                                    class="border-12"
                                    hide-details=true
                                    :disabled="disable_sectid"
                                ></v-autocomplete>
                            </v-col>
                            <v-col xs="6" sm="3" md="2" class="col-6">
                                <h6 class="blue-lcd" style="font-weight:600">Period Start</h6>
                                <v-menu ref="modal" 
                                    v-model="modal" 
                                    :close-on-content-click="false" 
                                    transition="scale-transition" 
                                    offset-y 
                                    max-width="290px" 
                                    min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        dense
                                        solo 
                                        v-model="period" 
                                        label="Period" 
                                        persistent-hint 
                                        append-icon="mdi-calendar" 
                                        v-bind="attrs" 
                                        v-on="on" 
                                        class="ma-0 pa-0 border-12" 
                                        hide-details=true>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker 
                                        v-model="period" 
                                        type="month" 
                                        no-title @input="modal = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col> 
                            <v-col xs="6" sm="3" md="2" class="col-6">
                                <h6 class="blue-lcd" style="font-weight:600">Period End</h6>
                                <v-menu ref="modal_end" 
                                    v-model="modal_end" 
                                    :close-on-content-click="false" 
                                    transition="scale-transition" 
                                    offset-y 
                                    max-width="290px" 
                                    min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        dense
                                        solo 
                                        v-model="periodEnd" 
                                        label="Period" 
                                        persistent-hint 
                                        append-icon="mdi-calendar" 
                                        v-bind="attrs" 
                                        v-on="on" 
                                        class="ma-0 pa-0 border-12" 
                                        hide-details=true>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker 
                                        v-model="periodEnd" 
                                        type="month" 
                                        no-title @input="modal_end = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>    
                            <v-col cols="12" xs="12" sm="3" md="2" class="col-12">
                                <v-btn class="mt-7 border-12" style="padding: 19px;" small color="primary" elevation="2" @click="fetchData()" :disabled="disabled_button">Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-1">
            <v-col class="col-6" xs="6" sm="6" md="3" v-if="conclusions.length > 0">
                <v-card outlined class="p-1 blue lighten-5" min-width="300" height="150">
                    <v-card-title class="p-1 blue--text">
                        <v-icon color="blue" class="mr-2">
                            mdi-album
                        </v-icon> Summary
                    </v-card-title>
                    <v-container fluid class="fill-height" fluid style="max-height: 90px">
                        <v-row align="center" justify="center">
                            <v-col cols="10" class="text-center">
                                <h3 style="font-weight: 600;" class="blue--text">
                                    IDR. {{$store.getters.convertToCurrencyUs(this.total_department_cost)}}
                                </h3>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
            <v-col xs="6" sm="6" md="9" class="col-6">
                <v-sheet
                    class="mx-auto"
                >
                    <v-slide-group
                    multiple
                    show-arrows
                    >
                        <v-slide-item class="mx-1" v-for="(conclusion, key) in conclusions" :key="key">
                            <v-card outlined class="p-1" min-width="300">
                                <v-card-title class="p-1">
                                    <v-row>
                                        <v-col cols="12">
                                            <div class="d-flex p-2">
                                                <p class="pb-1 mb-0">{{ conclusion.dept_id }} </p>
                                                <v-spacer></v-spacer>
                                                <h6 style="font-weight:600" class="mt-0 pt-0">
                                                    {{$store.getters.convertToCurrencyUs(conclusion.dept_cost)}}
                                                </h6>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card-title>
                                <v-card-text class="mt-0 pa-1">
                                    <v-sheet
                                        class="mx-auto"
                                        max-width="400"
                                    >
                                        <v-slide-group
                                        multiple
                                        show-arrows
                                        >
                                            <v-slide-item class="mx-1" v-for="(section, k) in conclusion.section" :key="k">
                                                <v-card class="rounded-l" outlined max-width="180">
                                                    <v-card-text class="pl-4 pr-4">
                                                        <v-row>
                                                            <v-col cols="12" class="mb-0 pb-0">
                                                                <p class="mb-1 pb-1" style="font-size: 12px;">{{section.sect_name}}</p>
                                                            </v-col>
                                                            <v-col cols="12" class="mt-0 pt-0">
                                                                <h6 style="font-weight:600;text-align: center;" class="mt-0 pt-0">
                                                                    {{$store.getters.convertToCurrencyUs(section.cost)}}
                                                                </h6>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>
                                            </v-slide-item>
                                        </v-slide-group>
                                    </v-sheet>
                                </v-card-text>
                            </v-card>
                        </v-slide-item>
                    </v-slide-group>
                </v-sheet>
            </v-col>
            <v-col cols="12">
                <v-card class="rounded-l elevation-5">
                    <v-card-title>
                        <v-text-field
                            solo
                            class="border-12"
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                            style="max-width:400px"
                            @keyup.enter="fetchData()"
                        
                        ></v-text-field>
                        <v-spacer></v-spacer>
                        <v-btn
                        small
                        color="#fff"
                        class="py-5 mr-3"
                        @click="fetchData()"
                        >
                            <v-icon>mdi-cached</v-icon>
                        </v-btn>
                        <v-btn
                        small
                        color="#005c37"
                        class="py-5 mr-3 text-white"
                        >
                            <v-icon>mdi-file-excel</v-icon>
                            <download-excel
                                class="text"
                                :fetch           = "exportExcel"
                                :fields="headersColumn"
                                :before-generate = "startDownload"
                                :before-finish   = "finishDownload">
                                Export Excel
                            </download-excel>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table
                            dense
                            :headers="headers"
                            :items="department_costs"
                            class="elevation-1 mt-3"
                            :items-per-page="30"
                            :footer-props="{
                                'items-per-page-options': [10, 20, 30, 40, 50, -1]
                            }"
                            :loading="$store.state.overlay"
                            height="400"
                            fixed-header
                            :divider="true"
                            :light="true"
                            :item-class="tr_datatable"
                            group-by="dept_name"
                            show-group-by
                        >    
                            <template v-slot:[`item.tr_date`]="{ item }">
                                {{ (new Date(new Date(item.tr_date) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) }}
                            </template> 
                            <template v-slot:[`item.amt_item`]="{ item }">
                                {{ $store.getters.convertToCurrencyUs(item.amt_item) }}
                            </template> 
                            <template v-slot:[`item.qty`]="{ item }">
                                {{ $store.getters.convertToCurrencyUs(item.qty) }}
                            </template> 
                            <template v-slot:[`item.wgt`]="{ item }">
                                {{ $store.getters.convertToCurrencyUs(item.wgt) }}
                            </template> 
                            <!-- <template v-slot:[`item.amt_item`]="{ item }">
                                {{ $store.getters.convertToCurrencyUs(item.amt_item) }}
                            </template>  -->
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {filter} from "@/backend-api/filter/index"
import {backendApi} from "@/backend-api/backend-api-sr"

export default {
    data() {
        return {
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            timeout: 7500,
            search:'',
            breadcumbs: [
                {
                    text: 'SMS',
                    disabled: false,
                    href: '/admin/sms',
                },
                {
                    text: 'Finance Report',
                    disabled: true,
                },
                {
                    text: 'Department Cost',
                    disabled: true,
                }
            ],
            departments: [],
            department: '',
            section: '',
            sections: [],
            modal: false,
            period: new Date().toISOString().substr(0, 7),
            department_costs: [],
            disable_deptid: false,
            disable_sectid: false,
            headers:[
                { text: 'TR ID', value: 'tr_id' , align:'left', groupable: false,width:'150'},
                { text: 'Section', value: 'sect_name' , align:'left', groupable: false},
                { text: 'Period', value: 'period' , align:'left', groupable: false},
                { text: 'Trans Date', value: 'tr_date' , align:'left', groupable: false},
                { text: 'WH Dest', value: 'wh_dest' , align:'left', groupable: false},
                { text: 'Descr', value: 'description' , align:'left', groupable: false},
                { text: 'Varian', value: 'varian' , align:'left', groupable: false},
                { text: 'Uom', value: 'uom' , align:'left', groupable: false},
                { text: 'WH ID', value: 'wh_id' , align:'left', groupable: false},
                { text: 'Bin ID', value: 'bin_id' , align:'left', groupable: false},
                { text: 'Defect', value: 'defect' , align:'right',groupable: false},
                { text: 'Qty', value: 'qty' , align:'right',groupable: false},
                { text: 'Wgt', value: 'wgt' , align:'right', groupable: false},
                { text: 'Amount', value: 'amt_item' , align:'right', groupable: false},
                { text: 'Remark', value: 'remark' , align:'left', groupable: false},
            ],
            headersColumn: {
                'TR ID': 'tr_id',
                'Department': 'dept_name',
                'Section': 'sect_name',
                'Trans Date': 'tr_date',
                'WH Dest': 'wh_dest',
                'Description': 'description',
                'Paid to': 'paid_to',
                'Varian': 'varian',
                'Uom': 'uom',
                'WH ID': 'wh_id',
                'Bin ID': 'bin_id',
                'Defect': 'defect',
                'Qty': 'qty',
                'Wgt': 'wgt',
                'Amount': 'amt_item',
                'Remark': 'remark'
            },
            modal_end:false,
            periodEnd: new Date().toISOString().substr(0, 7),
            conclusions: [],
            disabled_button: false
        }
    },
    computed:{
        total_department_cost(){
            var total_department_cost = 0
            for (let index = 0; index < this.department_costs.length; index++) {
                total_department_cost = total_department_cost + Number(this.department_costs[index].amt_item);
            }
            console.log(total_department_cost);
            return total_department_cost
        },
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        this.disabled_button = true
        // await this.getEnvConf()
        await this.getDepartment()
        this.$store.dispatch('setOverlay', false)
        this.disabled_button = false
    },
    methods: {
        tr_datatable(item) {
            const rowClass = 'tr_datatable'
            return rowClass;
        },
        async getEnvConf(){
            const respVarDeptID = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=STARCON&appl_id=WEBSTARCON&var_id=DEPTID&groupid=${this.$store.state.sms.group_id}`, null, false, false, false) 

            if (respVarDeptID.data.data != null) {
                this.department = respVarDeptID.data.data
                this.disable_deptid = true
                await this.getSection()
            } else {
                this.department = ''
                this.disable_deptid = false
            }

            const respVarSectID = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=STARCON&appl_id=WEBSTARCON&var_id=SECTID&groupid=${this.$store.state.sms.group_id}`, null, false, false, false) 

            if (respVarSectID.data.data != null) {
                this.section = respVarSectID.data.data
                this.disable_sectid = true
            } else {
                this.section = ''
                this.disable_sectid = false
            }
        },
        async getDepartment(){
            const respData = await backendApi.fetchCore(`/api/master/starcon/department`, null, false, false, false) 
            if (respData.status === 200) {
                this.departments = respData.data
            }
        },
        // async getSection(event){
        //     if (event === '' || event === null) {
        //         this.section = ''
        //     } else {
        //         this.$store.dispatch('setOverlay', true)
        //         const respData = await backendApi.fetchCore(`/api/master/starcon/section?dept_id=${this.department ? this.department : ''}`, null, false, false, false) 
        //         if (respData.status === 200) {
        //             this.sections = respData.data
        //         }
        //         this.$store.dispatch('setOverlay', false)
        //     }
        // },
        async getSection(event) {
            if (event === '' || event === null) {
                this.section = ''
            } else {
                this.$store.dispatch('setOverlay', true)
                try {
                    const respData = await backendApi.fetchCore(
                        '/api/master/starcon/section',
                        {
                            dept_id: this.department || ''
                        }, 
                        true, // Set to true to indicate a POST request
                        false, 
                        false
                    )
                    if (respData.status === 200) {
                        this.sections = respData.data
                    }
                } catch (error) {
                    console.error('Error fetching sections:', error)
                } finally {
                    this.$store.dispatch('setOverlay', false)
                }
            }
        },
        async fetchData(){
            this.$store.dispatch('setOverlay', true)
            this.department_costs = []
            await this.fetchDashboard()
            const respData = await backendApi.fetchCore(`/api/starcon/finance/department-cost?period_start=${this.period ? new Date(this.period).toISOString().substr(0, 7).replace(/-/g,"") : new Date().toISOString().substr(0, 7).replace(/-/g,"")}&period_end=${this.periodEnd ? new Date(this.periodEnd).toISOString().substr(0, 7).replace(/-/g,"") : new Date().toISOString().substr(0, 7).replace(/-/g,"")}&search=${this.search ? this.search : ''}&dept_id=${this.department ? this.department : ''}&sect_id=${this.section ? this.section : ''}`, null, false, false, false) 
            if (respData.status === 200) {
                this.department_costs = respData.data
            }
            this.$store.dispatch('setOverlay', false)
        },
        async fetchDashboard(){
            this.conclusions = []
            const respData = await backendApi.fetchCore(`/api/starcon/finance/department-cost/dashboard?period_start=${this.period ? new Date(this.period).toISOString().substr(0, 7).replace(/-/g,"") : new Date().toISOString().substr(0, 7).replace(/-/g,"")}&period_end=${this.periodEnd ? new Date(this.periodEnd).toISOString().substr(0, 7).replace(/-/g,"") : new Date().toISOString().substr(0, 7).replace(/-/g,"")}&search=${this.search ? this.search : ''}&dept_id=${this.department ? this.department : ''}&sect_id=${this.section ? this.section : ''}`, null, false, false, false) 
            if (respData.status === 200) {
                this.conclusions = respData.data.data ? respData.data.data : []
            }
        },
        async exportExcel(){
            const respData = await backendApi.fetchCore(`/api/starcon/finance/department-cost?period_start=${this.period ? new Date(this.period).toISOString().substr(0, 7).replace(/-/g,"") : new Date().toISOString().substr(0, 7).replace(/-/g,"")}&period_end=${this.periodEnd ? new Date(this.periodEnd).toISOString().substr(0, 7).replace(/-/g,"") : new Date().toISOString().substr(0, 7).replace(/-/g,"")}&search=${this.search ? this.search : ''}&dept_id=${this.department ? this.department : ''}&sect_id=${this.section ? this.section : ''}`, null, false, false, false)

            if (respData.status === 200) {
                return respData.data
            }
        },
        startDownload(){
            this.$store.dispatch('setOverlay', true)
        },
        finishDownload(){
            this.$store.dispatch('setOverlay', false)
        },
    },
    
}
</script>
<style scoped>
.v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .9;
    position: absolute;
    width: 100%;
}
.tr_datatable{
  background-color: #F5F7F8 !important;
}
/* .v-card__text {
  flex-grow: 1;
  overflow: auto;
}
.v-card {
  flex-grow: 1;
  overflow: auto;
} */
</style>